import React from 'react';
import PropTypes from 'prop-types';
import { lumosRaspberry, fontStyles } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  failure: {
    color: lumosRaspberry
  },
  filled: {
    opacity: 1,
    transition: 'opacity 250ms'
  }
});

function InputFeedbackMessage({ feedback, type, receivedInput }) {
  if (receivedInput === false) {
    return <p id={`${type}_error`} />;
  }

  return (
    <p
      data-cy={`input_feedback_message_${type}`}
      id={`${type}_error`}
      className={css(fontStyles.largeCaption, styles.failure, styles.filled)}
    >
      {feedback}
    </p>
  );
}

InputFeedbackMessage.propTypes = {
  feedback: PropTypes.string,
  receivedInput: PropTypes.bool,
  type: PropTypes.string.isRequired
};

InputFeedbackMessage.defaultProps = {
  feedback: ''
};

export default InputFeedbackMessage;
