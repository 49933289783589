import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Link } from 'gatsby';
import { StyleSheet, css } from 'aphrodite';
import ReactHtmlParser from 'react-html-parser';

// utils
import i18n from 'utils/i18n';

// styles
import { mediaQuery } from 'styles/helpers/layout';
import { whiteAt98, fonts } from '@lumoslabs/react_lux';

const styles = StyleSheet.create({
  // match size of input labels, which are capped at a certain size on large screens
  alreadyCol: {
    marginTop: -30,
    background: whiteAt98,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.14)',
    padding: '35px 35px'
  },
  alreadyFont: fonts.customFontScaling(fonts.h6, {
    [mediaQuery.minWidth.desktopSmall]: {
      fontSize: '1.0266em'
    }
  }),
  already: {
    textAlign: 'center'
  },
  link: {
    textDecoration: 'underline'
  }
});
function AlreadyHaveAccount({ customLoginUrl, customLoginText }) {
  return (
    <Col xs="14" className={css(styles.alreadyCol)}>
      <p className={css(styles.alreadyFont, styles.already)}>
        {ReactHtmlParser(i18n.t('RegistrationForm.AlreadyHaveAccount'))}
        <Link
          to={customLoginUrl}
          className={css(styles.link)}
          data-track
          data-track-link-click="reg_login_link"
          data-track-location="body"
        >
          {customLoginText}
        </Link>
      </p>
    </Col>
  );
}

AlreadyHaveAccount.propTypes = {
  customLoginUrl: PropTypes.string.isRequired,
  customLoginText: PropTypes.string.isRequired
};

export default AlreadyHaveAccount;
