import React from 'react';
import { Row, Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';

import * as layout from 'styles/helpers/layout';

import CardCarousel from '../ui/CardCarousel/Index';
import CheckerboardStack from '../ui/CheckerboardStack/Index';
import BasicCard from '../ui/BasicCard';

const styles = StyleSheet.create({
  carousel: {
    textAlign: 'center'
  },
  cardsRow: {
    marginTop: 20,
    ...layout.alignTop
  },
  centeredCol: {
    display: 'inline-block',
    float: 'none',
    /* inline-block space fix */
    marginRight: -4,
    textAlign: 'center',
    [layout.mediaQuery.maxWidth.tablet]: {
      paddingBottom: 30,
      ':last-child': {
        paddingBottom: 0
      }
    }
  }
});

function renderCards(cards, cardLayout, additionalStyles) {
  switch (cardLayout) {
    case 'checkerboard stack':
      return <CheckerboardStack cards={cards} />;
    case 'carousel':
      return (
        <Col md={{ size: 10, offset: 1 }} className={css(styles.carousel)}>
          <CardCarousel cards={cards} />
        </Col>
      );
    default:
      return (
        <>
          {cards.map((card, index) => (
            <Col
              /* eslint-disable-next-line react/no-array-index-key */
              key={`${card.id}${index}`}
              sm="12"
              md={12 / cards.length}
              className={css(styles.centeredCol)}
            >
              <BasicCard card={card} additionalStyles={additionalStyles} />
            </Col>
          ))}
        </>
      );
  }
}

function Cards({ cards, layout, additionalStyles }) {
  return <Row className={css(styles.cardsRow)}>{renderCards(cards, layout, additionalStyles)}</Row>;
}

export default Cards;
