import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Carousel from 'nuka-carousel';
import Image from 'gatsby-image';

import leftArrow from './images/leftarrow.png';
import rightArrow from './images/rightarrow.png';

const styles = StyleSheet.create({
  centeredRow: {
    textAlign: 'center'
  },
  centeredCol: {
    display: 'inline-block',
    float: 'none',
    /* inline-block space fix */
    marginRight: -4,
    textAlign: 'center'
  },
  controlArrow: {
    width: 15,
    cursor: 'pointer'
  },
  carouselImage: {
    width: '70%',
    margin: '0px auto'
  },
  carouselTitle: {
    fontSize: 24,
    margin: 12
  },
  carouselText: {
    opacity: 0.7
  },
  carouselCopy: {
    marginBottom: 60,
    fontSize: 16,
    lineHeight: '25.6px',
    marginLeft: 10,
    marginRight: 10
  }
});

class CardCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.cards = props.cards;
  }

  componentDidMount() {
    // fixes issue which causes height of carousel to be doubled when initially loaded
    // https://github.com/FormidableLabs/nuka-carousel/issues?q=is%3Aissue+gatsby+is%3Aclosed#issuecomment-464408073
    setTimeout(() => {
      this.carousel.setDimensions();
    }, 0);
  }

  render() {
    return (
      <Carousel
        ref={carousel => {
          this.carousel = carousel;
        }}
        autoplay
        initialSlideHeight="200"
        renderCenterLeftControls={({ previousSlide }) => (
          <a onClick={previousSlide}>
            <img src={leftArrow} alt="left arrow" className={css(styles.controlArrow)} />
          </a>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <a onClick={nextSlide}>
            <img src={rightArrow} alt="right arrow" className={css(styles.controlArrow)} />
          </a>
        )}
      >
        {this.cards.map((card, index) => (
          <div key={'carouselCard{$index}'}>
            <Image className={css(styles.carouselImage)} fluid={card.graphic.fluid} />
            <h3 className={css(styles.carouselTitle)}>
              <span className={css(styles.carouselText)}>{index + 1}.</span> {card.headline}
            </h3>
            <div className={css(styles.carouselText, styles.carouselCopy)}>{card.copy}</div>
          </div>
        ))}
      </Carousel>
    );
  }
}

export default CardCarousel;
