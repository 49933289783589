import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import Helmet from 'react-helmet';

import i18n from 'utils/i18n';

import Layout from 'components/layout';
import Metatags from 'components/Metatags';
import RichBackgroundStacker from 'components/landing/stackers/RichBackgroundStacker';
import RegistrationFormStacker from 'components/landing/stackers/RegistrationFormStacker';
import InfoStacker from 'components/landing/stackers/InfoStacker';

const typesToComponents = {
  ContentfulRichBackgroundStacker: RichBackgroundStacker,
  ContentfulRegistrationFormStacker: RegistrationFormStacker,
  ContentfulInfoStacker: InfoStacker
};

function Stacker({ content }) {
  const { __typename } = content;
  /* eslint-disable-next-line no-underscore-dangle */
  const StackerComponent = typesToComponents[__typename];
  // If stacker query and component is added to the LP in contentful but not
  // yet added to code, don't try to render it.
  if (typeof StackerComponent === 'undefined') {
    return null;
  }
  return <StackerComponent content={content} />;
}

class StackedLandingPageTemplate extends React.Component {
  stashReferParams() {
    const {
      location: { search }
    } = this.props;
    const cookies = new Cookies();
    const parsed = queryString.parse(search);
    Object.keys(parsed).forEach((key) => {
      cookies.set(key, parsed[key], { path: '/' });
    });
  }

  render() {
    const {
      location,
      data: { contentfulSimpleStackedLandingPage: page },
      pageContext: { locale, pagePath }
    } = this.props;
    // expecting something like ?refer=1234&sub=someLabel&..., put it all in the cookie to let
    // lumos_rails feast on upon registration.
    this.stashReferParams();
    return (
      <Layout locale={locale} location={location} footerOptions={{ renderLanguageLinks: false }}>
        <Helmet>
          <meta
            name="robots"
            content="noindex"
            /* per SEO consultant Doug, LPs shouldn't be indexed. They were
          outperforming more relevant pages (and thus superceding them in results) */
          />
        </Helmet>
        <Metatags
          title={i18n.t('homePage.title')}
          description={i18n.t('homePage.description')}
          type="website"
          canonical={pagePath}
          locale={locale}
        />
        <div>
          {page.stackers.map((stacker, index) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <div key={`${stacker.id}-${index}`}>
              <Stacker content={stacker} />
            </div>
          ))}
        </div>
      </Layout>
    );
  }
}

StackedLandingPageTemplate.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({
    /* eslint-disable-next-line react/forbid-prop-types */
    contentfulSimpleStackedLandingPage: PropTypes.any
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
    pagePath: PropTypes.string.isRequired
  }).isRequired
};

Stacker.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    node_locale: PropTypes.string.isRequired,
    __typename: PropTypes.string
  }).isRequired
};

export default StackedLandingPageTemplate;

export const pageQuery = graphql`
  query SimpleStackedLandingPageBySlug($slug: String!, $locale: String!) {
    contentfulSimpleStackedLandingPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      stackers {
        ... on ContentfulRichBackgroundStacker {
          id
          __typename
          node_locale
          ...richBackgroundStackerFragment
        }
        ... on ContentfulRegistrationFormStacker {
          id
          __typename
          node_locale
          ...registrationFormStackerFragment
        }
        ... on ContentfulInfoStacker {
          id
          __typename
          node_locale
          ...infoStackerFragment
        }
      }
    }
  }
`;
