import React from 'react';
import i18n from 'utils/i18n';

// child components
import TextInputListItem from './TextInputListItem';

const fieldOrderArray = ['name', 'email', 'password'];
const i18nPrefix = 'RegistrationForm.TextInputList';

function TextInputList({ name, email, password, handleInputChange, formErrors }) {
  const inputFieldsMap = { name, email, password };

  return fieldOrderArray.map(field => (
    <TextInputListItem
      key={`input-${field}`}
      handleInputChange={handleInputChange}
      formErrors={formErrors}
      value={inputFieldsMap[field]}
      type={field}
      placeholderText={i18n.t(`${i18nPrefix}.placeholderText.${field}`)}
      labelText={i18n.t(`${i18nPrefix}.label.${field}`)}
      showFeedback
    />
  ));
}

export default TextInputList;
