import React from 'react';
import { StyleSheet, css } from 'aphrodite';

const styles = StyleSheet.create({
  checkerboard: {
    width: 675,
    margin: '0px auto',
    '@media (max-width: 500px)': {
      margin: 20
    }
  },
  checkerboardBlurb: {
    position: 'relative',
    top: '50%',
    transform: 'perspective(1px) translateY(-50%)',
    '@media (max-width: 768px)': {
      height: 270
    }
  },
  checkerboardItem: {
    maxWidth: 300,
    marginBottom: 30,
    height: 300,
    verticalAlign: 'center',
    '@media (max-width: 500px)': {
      margin: '0px auto',
      textAlign: 'center',
      marginBottom: 30,
      height: 230
    }
  },
  checkerboardGraphic: {
    '@media (max-width: 768px)': {
      maxWidth: 225
    }
  },
  left: {
    float: 'left',
    width: '50%',
    '@media (max-width: 500px)': {
      float: 'none',
      width: '90%'
    }
  },
  right: {
    float: 'right',
    width: '50%',
    '@media (max-width: 500px)': {
      float: 'none',
      width: '90%'
    }
  },
  cardHeadline: {
    fontSize: '1.45em',
    lineHeight: '1.47em',
    fontWeight: 'normal',
    marginBlockStart: 12,
    marginBlockEnd: 12
  },
  cardCopy: {
    opacity: 0.8,
    fontSize: 16,
    lineHeight: '25.6px'
  },
  heading: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginInlineStart: 0,
    marginInlineEnd: 0
  },
  clear: {
    clear: 'both'
  }
});

function CheckerboardStack({ cards }) {
  return (
    <div className={css(styles.checkerboard)}>
      {cards.map((card, index) => {
        return (
          <div>
            <div className={css(styles.checkerboardItem, styles.checkerboardGraphic, (index%2==0) ? styles.left : styles.right)}>
              <img fluid={card.graphic.fluid} />
            </div>
            <div className={css(styles.checkerboardItem, (index%2==0) ? styles.right : styles.left)}>
              <div className={css(styles.checkerboardBlurb)}>
                <h3 className={css(styles.heading, styles.cardHeadline)}>{card.headline}</h3>
                <div className={css(styles.cardCopy)}>{card.copy}</div>
              </div>
            </div>
            <div className={css(styles.clear)}/>
          </div>
        )
      })}
    </div>
  )
}

export default CheckerboardStack;
