import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { lumosTeal, lumosWhite, fonts } from '@lumoslabs/react_lux';
import { css, StyleSheet } from 'aphrodite';

function createStyles({ backgroundColor, textColor }) {
  const styles = StyleSheet.create({
    wrapper: {
      backgroundColor,
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 30
    },
    image: {
      marginTop: 0,
      marginBottom: 20
    },
    header: {
      ...fonts.h4,
      marginTop: 20,
      marginBottom: 20,
      color: textColor
    },
    content: {
      ...fonts.h6,
      color: textColor,
      whiteSpace: 'pre-line',
      ':nth-child(1n) > p': {
        margin: 0
      },
      ':nth-child(1n) > ul': {
        margin: 0,
        paddingLeft: 20,
        whiteSpace: 'normal'
      }
    }
  });
  return styles;
}

function ValueProposition(props) {
  const {
    imageAltText: { imageAltText },
    copy: { header, subheader },
    image: { fluid },
    customStyles
  } = props;
  const styles = createStyles(customStyles);

  return (
    <div className={css(styles.wrapper)}>
      <Img fluid={fluid} className={css(styles.image)} alt={imageAltText || ''} />
      <h1 className={css(styles.header)}>{header}</h1>
      <div
        className={css(styles.content)}
        /* eslint-disable-next-line react/no-danger  */
        dangerouslySetInnerHTML={{
          __html: subheader.childMarkdownRemark.html
        }}
      />
    </div>
  );
}

ValueProposition.propTypes = {
  copy: PropTypes.shape({
    header: PropTypes.string,
    subheader: PropTypes.string.isRequired
  }).isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.object.isRequired
  }).isRequired,
  imageAltText: PropTypes.string,
  customStyles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string
  })
};

ValueProposition.defaultProps = {
  customStyles: {
    backgroundColor: lumosTeal,
    textColor: lumosWhite
  },
  imageAltText: ''
};

export default ValueProposition;
