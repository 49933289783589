import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';

import { layoutStyles, mediaQuery } from 'styles/helpers/layout';
import { whiteAt98, fonts } from '@lumoslabs/react_lux';

import i18n from 'utils/i18n';
import InputFeedbackManager from 'components/ui/forms/InputFeedbackManager';
import TextInputList from './TextInputList';
import BirthdayInputList from './BirthdayInputList';
import TermsOfServiceAgreement from './TermsOfServiceAgreement';
import SignUpButton from './SignUpButton';
import FacebookLoginButton from './FacebookLoginButton';
import GoogleLoginButton from './GoogleLoginButton';

const styles = StyleSheet.create({
  requiredFont: fonts.customFontScaling(fonts.smallCaption),
  form: {
    background: whiteAt98,
    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.14)',
    padding: '35px 35px',
    [mediaQuery.maxWidth.tablet]: {
      padding: '24px 0'
    }
  },
  termsOfService: {
    marginTop: 10
  },
  buttonOffset: {
    marginTop: 10
  },
  otherButtonOffset: {
    marginTop: 10,
    paddingLeft: 0,
    paddingRight: 0
  },
  optionsText: fonts.customFontScaling(fonts.h6, {
    [mediaQuery.minWidth.desktopSmall]: {
      fontSize: '1.0266em'
    }
  })
});

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      name: '',
      password: '',
      dobYear: '',
      dobMonth: '',
      dobDay: '',
      googleAccessToken: '',
      googleIdToken: '',
      formErrors: { email: '', name: '', password: '', birthday: '' },
      validFields: {
        emailValid: false,
        nameValid: false,
        passwordValid: false,
        birthdayValid: false
      },
      formValid: false
    };
  }

  validateField = (fieldName, value) => {
    const { dobDay, dobMonth, dobYear, formErrors, validFields } = this.state;
    const fieldValidationErrors = formErrors;
    const bDay = { day: dobDay, month: dobMonth, year: dobYear };
    const { emailValid, nameValid, passwordValid, birthdayValid } = validFields;
    const fieldValidMap = {
      email: emailValid,
      name: nameValid,
      password: passwordValid,
      birthday: birthdayValid
    };

    switch (fieldName) {
      case 'email':
      case 'name':
      case 'password':
        fieldValidMap[fieldName] = InputFeedbackManager.checkSuccess(fieldName, value);
        fieldValidationErrors[fieldName] = InputFeedbackManager.respondToInput(
          fieldValidMap[fieldName],
          fieldName,
          value
        );
        break;
      default:
        fieldValidMap.birthday = InputFeedbackManager.checkSuccess('birthday', bDay);
        fieldValidationErrors.birthday = InputFeedbackManager.respondToInput(fieldValidMap.birthday, 'birthday', bDay);
        break;
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        validFields: {
          emailValid: fieldValidMap.email,
          nameValid: fieldValidMap.name,
          passwordValid: fieldValidMap.password,
          birthdayValid: fieldValidMap.birthday
        }
      },
      this.validateForm
    );
  };

  validateForm = () => {
    const { csrfToken } = this.props;

    this.setState((prevState) => ({
      formValid:
        csrfToken.length > 0 &&
        prevState.validFields.emailValid &&
        prevState.validFields.nameValid &&
        prevState.validFields.passwordValid &&
        prevState.validFields.birthdayValid
    }));
  };

  googleSignInSuccessCallback = (email, googleAccessToken, googleIdToken) => {
    this.setState({ email, googleAccessToken, googleIdToken });
    this.formEl.submit();
  };

  handleInputChange = (event, stateField) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ [stateField]: value }, () => {
      this.validateField(stateField, value);
    });
  };

  render() {
    const { GATSBY_LUMOSITY_HOST } = process.env;
    const { csrfToken, locale } = this.props;
    const {
      name,
      email,
      password,
      dobYear,
      dobMonth,
      dobDay,
      googleAccessToken,
      googleIdToken,
      formErrors,
      formValid
    } = this.state;
    const csrfTokenPresent = csrfToken.length > 0;

    return (
      <form
        action={`${GATSBY_LUMOSITY_HOST}/registration`}
        method="post"
        className={css(styles.form)}
        ref={(formEl) => {
          this.formEl = formEl;
        }}
      >
        <input type="hidden" name="authenticity_token" value={csrfToken} />
        <input type="hidden" name="user[unique_name_is_unspecified" value="true" />
        <input type="hidden" name="id_token" value={googleIdToken} />
        <input type="hidden" name="gapi_access_token" value={googleAccessToken} />
        <Container fluid>
          <TextInputList
            name={name}
            email={email}
            password={password}
            handleInputChange={this.handleInputChange}
            formErrors={formErrors}
          />
          <BirthdayInputList
            dobYear={dobYear}
            dobMonth={dobMonth}
            dobDay={dobDay}
            handleInputChange={this.handleInputChange}
            formErrors={formErrors}
          />
          <Row className={css(layoutStyles.centerHorizontal)}>
            <p className={css(styles.requiredFont)}>{i18n.t('RegistrationForm.RequiredFields')}</p>
          </Row>
          <Row className={css(layoutStyles.centerHorizontal, styles.termsOfService)}>
            <TermsOfServiceAgreement locale={locale} />
            <SignUpButton buttonDisabled={!formValid} />
          </Row>
          <Row className={css(layoutStyles.centerHorizontal)}>
            <Col xs={11} xl={9} className={css(layoutStyles.centerHorizontal, styles.buttonOffset)}>
              <p className={css(styles.optionsText)}>{i18n.t('RegistrationForm.thirdPartyAuthOptions')}</p>
            </Col>
            <Col xs={11} xl={9} className={css(styles.otherButtonOffset)}>
              <GoogleLoginButton successCallBack={this.googleSignInSuccessCallback} disabled={!csrfTokenPresent} />
              <FacebookLoginButton csrfToken={csrfToken} disabled={!csrfTokenPresent} />
            </Col>
          </Row>
        </Container>
      </form>
    );
  }
}

RegistrationForm.propTypes = {
  csrfToken: PropTypes.string,
  locale: PropTypes.string.isRequired
};

RegistrationForm.defaultProps = {
  csrfToken: ''
};

export default RegistrationForm;
