import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';

// styles
import { mediaQuery } from 'styles/helpers/layout';
import { fonts } from '@lumoslabs/react_lux';

const styles = StyleSheet.create({
  // prevent input text from getting too large on large screens
  labelText: fonts.customFontScaling(fonts.h6, {
    [mediaQuery.minWidth.desktopSmall]: {
      fontSize: '1.0266em'
    }
  })
});

function InputLabel({ type, children }) {
  return (
    // rule is only failing because associated input is in another file
    /* eslint-disable jsx-a11y/label-has-for */
    <label htmlFor={`unique_${type}`} className={css(styles.labelText)}>
      {children}
    </label>
    /* eslint-enable jsx-a11y/label-has-for */
  );
}

InputLabel.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node
};

InputLabel.defaultProps = {
  type: '',
  children: null
};

export default InputLabel;
