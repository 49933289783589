import React from 'react';
import PropTypes from 'prop-types';
import { gray3, lumosTeal, lumosRaspberry, blackAt89, fonts } from '@lumoslabs/react_lux';
import { StyleSheet, css } from 'aphrodite';

// styles
import { mediaQuery } from 'styles/helpers/layout';

const inputStyles = StyleSheet.create({
  // prevent input text from getting too large on large screens
  inputText: fonts.customFontScaling(fonts.p, {
    [mediaQuery.minWidth.desktopSmall]: { fontSize: '1.2em' }
  }),
  input: {
    height: 48,
    width: '100%',
    paddingLeft: 14,
    boxSizing: 'border-box',
    color: blackAt89,
    borderWidth: '1px',
    borderColor: gray3,
    borderStyle: 'solid',
    borderRadius: '5px',
    '::placeholder': {
      color: gray3,
      paddingLeft: 2
    },
    transition: 'box-shadow 200ms linear',
    ':focus': {
      outline: 'none',
      transition: 'all 200ms linear'
    }
  },
  inputSuccessFocus: {
    ':focus': {
      border: `1px solid ${lumosTeal}`,
      boxShadow: `inset 0px 0px 0px 1px ${lumosTeal}`
    }
  },
  inputFailureFocus: {
    ':focus': {
      border: `1px solid ${lumosRaspberry}`,
      boxShadow: `inset 0px 0px 0px 1px ${lumosRaspberry}`
    }
  }
});

function TextInput({ styles, valid, ...rest }) {
  const inlineStyles = css(
    inputStyles.input,
    inputStyles.inputText,
    valid ? inputStyles.inputSuccessFocus : inputStyles.inputFailureFocus,
    styles
  );

  return <input className={inlineStyles} {...rest} />;
}

TextInput.propTypes = {
  styles: PropTypes.arrayOf(PropTypes.object),
  valid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired
};

TextInput.defaultProps = {
  styles: [],
  valid: true
};

export default TextInput;
