import React from 'react';
import { Col, Row } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';

import MainImage from './MainImage';

function stylize(styleOverrides) {
  return StyleSheet.create({
    centeredCol: {
      display: 'inline-block',
      float: 'none',
      /* inline-block space fix */
      marginRight: -4,
      textAlign: 'center'
    },
    centeredRow: {
      textAlign: 'center'
    },
    body: {
      margin: '12px auto',
      ...styleOverrides.body
    },
    subhead: {
      opacity: 0.8,
      fontSize: 20,
      lineHeight: '29px',
      fontWeight: 'normal'
    },
    header: {
      marginTop: '5%',
      ...styleOverrides.header
    },
    headline: {
      margin: '12px auto',
      ...styleOverrides.headline
    },
    subheadWrapper: {
      margin: '12px auto',
      ...styleOverrides.subhead
    }
  });
}

// Some landing pages have the main image first, then the headline
function renderMainImageAboveHeadline(location) {
  return location === 'above headline';
}

// Most landing pages have the main image in the middle
function renderMainImageBelowSubhead(location) {
  return location !== 'above headline';
}

function Header({ headline, subhead, body, styleOverrides, image, imageLocation, mainImageAltText }) {
  const styles = stylize(styleOverrides);
  let mainImage;
  if (image) {
    mainImage = (
      <MainImage
        fluid={image.fluid}
        styleOverrides={styleOverrides.mainImage}
        mainImageAltText={mainImageAltText || ''}
      />
    );
  }
  return (
    <React.Fragment>
      <Row className={css(styles.centeredRow)}>
        <Col className={css(styles.centeredCol)}>
          {renderMainImageAboveHeadline(imageLocation) && mainImage}
          <div className={css(styles.header)}>
            <div className={css(styles.headline)}>{headline && <h2>{headline}</h2>}</div>
            <div className={css(styles.subheadWrapper)}>
              {subhead && <h2 className={css(styles.subhead)}>{subhead}</h2>}
            </div>
            {body && (
              <div
                className={css(styles.body)}
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html
                }}
              />
            )}
          </div>
        </Col>
      </Row>
      <Row>{renderMainImageBelowSubhead(imageLocation) && mainImage}</Row>
    </React.Fragment>
  );
}

Header.propTypes = {
  headline: PropTypes.string,
  subhead: PropTypes.string,
  body: PropTypes.string,
  styleOverrides: PropTypes.string,
  image: PropTypes.string,
  imageLocation: PropTypes.string,
  mainImageAltText: PropTypes.string
}.isRequired;

export default Header;
