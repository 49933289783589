import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import { Row, Col } from 'reactstrap';

// styles
import { blackAt62, fonts, mediaQuery } from '@lumoslabs/react_lux';

const styles = StyleSheet.create({
  header: {
    ...fonts.h4
  },
  headerRow: {
    marginLeft: 15,
    marginTop: 15,
    marginRight: 15
  },
  subheader: {
    ...fonts.h6,
    color: blackAt62
  },
  subheaderCol: {
    marginTop: 5,
    marginBottom: 20
  },
  container: {
    [mediaQuery.maxWidth.tablet]: {
      textAlign: 'center'
    },
    [mediaQuery.minWidth.tablet]: {
      textAlign: 'left'
    }
  }
});

function Header({ title, subtitle }) {
  return (
    <Row className={css(styles.headerRow, styles.container)}>
      {title && (
        <Col xs="12" className={css(styles.headerCol)}>
          <h4 className={css(styles.header)}>{title}</h4>
        </Col>
      )}
      {subtitle && (
        <Col xs="12" className={css(styles.subheaderCol)}>
          <h6 className={css(styles.subheader)}>{subtitle}</h6>
        </Col>
      )}
    </Row>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default Header;
