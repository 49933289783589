import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';
import Lottie from './Lottie';

// styles
import { layoutStyles, mediaQuery } from 'styles/helpers/layout';

// animations
import NegativeFeedbackIcon from './images/NegativeFeedbackIcon.json';
import PositiveFeedbackIcon from './images/PositiveFeedbackIcon.json';

const styles = StyleSheet.create({
  sizeAndPositionContainer: {
    position: 'absolute',
    heigth: 28,
    width: 28,
    top: 38,
    right: '-24px',
    [mediaQuery.maxWidth.desktopSmall]: {
      right: 5
    },
    [mediaQuery.maxWidth.tablet]: {
      height: 24,
      width: 24,
      right: 14
    },
    [mediaQuery.maxWidth.mobileLarge]: {
      height: 20,
      width: 20
    },
    [mediaQuery.maxWidth.mobileSmall]: {
      right: 10
    }
  }
});

const resolveLottieAnimation = itemValid => ({
  loop: false,
  autoplay: true,
  animationData: itemValid ? PositiveFeedbackIcon : NegativeFeedbackIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet'
  }
});

function InputFeedbackIcon({ itemValid, receivedInput, ariaDescribedby }) {
  if (receivedInput === false) {
    return null;
  }
  return (
    <div className={css(layoutStyles.centerHorizontal, layoutStyles.centerVertical, styles.sizeAndPositionContainer)}>
      <Lottie ariaDescribedby={ariaDescribedby} ariaLabel="error-icon" options={resolveLottieAnimation(itemValid)} />
    </div>
  );
}

InputFeedbackIcon.propTypes = {
  ariaDescribedby: PropTypes.string,
  receivedInput: PropTypes.bool,
  itemValid: PropTypes.bool
};

InputFeedbackIcon.defaultProps = {
  ariaDescribedby: undefined,
  receivedInput: false,
  itemValid: undefined
};

export default InputFeedbackIcon;
