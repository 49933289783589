import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { RoundedButton, whiteAt98, fontStyles } from '@lumoslabs/react_lux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import i18n from 'utils/i18n';

const styles = StyleSheet.create({
  button: {
    display: 'inline-block',
    position: 'relative',
    paddingLeft: 34,
    paddingRight: 20,
    width: '50%',
    textAlign: 'center',
    color: whiteAt98,
    backgroundColor: '#3B5998',
    ':hover': {
      backgroundColor: '#3F5fA3'
    },
    ':active': {
      backgroundColor: '#19356B'
    }
  },
  icon: {
    position: 'absolute',
    left: 20,
    top: 17
  }
});

class FacebookLoginButton extends React.Component {
  static handleFacebookClick(event, onClick) {
    event.preventDefault();
    onClick();
  }

  static reenableSpinner() {
    // NOOP
  }

  constructor(props) {
    super(props);
    this.handleFacebookResponse = this.handleFacebookResponse.bind(this);
  }

  facebookCallbackUrl() {
    const { authType } = this.props;
    const { GATSBY_LUMOSITY_HOST } = process.env;
    const urlParams = [window.location.search.substr(1), `type=${authType}`, 'registration_confirmed=false'].join('&');
    return `${GATSBY_LUMOSITY_HOST}/auth/facebook/callback/?${urlParams}`;
  }

  handleFacebookResponse(response) {
    const { csrfToken } = this.props;
    response.signed_request = response.signedRequest;
    fetch(this.facebookCallbackUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken
      },
      credentials: 'include',
      body: JSON.stringify(response)
    })
      .then(authResponse =>
        authResponse.json().then(({ return_url: returnUrl }) => {
          // NOTE: This code DOES NOT handle the "confirm registration" case (which is an another value returned in this
          // response). This would need to be implemented if we ever use SSG to replace /login
          if (returnUrl) {
            window.location.href = returnUrl;
          }
          FacebookLoginButton.reenableSpinner();
        })
      )
      .catch(() => {
        FacebookLoginButton.reenableSpinner();
      });
  }

  render() {
    const { GATSBY_FACEBOOK_APP_ID } = process.env;
    const { disabled } = this.props;
    return (
      <FacebookLogin
        appId={GATSBY_FACEBOOK_APP_ID}
        callback={this.handleFacebookResponse}
        cookie
        xfbml
        version="3.0"
        render={({ onClick }) => (
          <RoundedButton
            styles={[fontStyles.a, styles.button]}
            onClick={event => FacebookLoginButton.handleFacebookClick(event, onClick)}
            disabled={disabled}
            // eslint-disable-next-line no-script-url
            href="javascript:void(0);"
            track="true"
            trackButtonPress="signup_facebook"
            trackLocation="body"
          >
            <FontAwesomeIcon icon={faFacebookF} className={css(styles.icon)} size="s" />
            {i18n.t('registrationForm.facebookLoginButton.signUp')}
          </RoundedButton>
        )}
      />
    );
  }
}

FacebookLoginButton.propTypes = {
  csrfToken: PropTypes.string,
  authType: PropTypes.string,
  disabled: PropTypes.bool
};

FacebookLoginButton.defaultProps = {
  csrfToken: '',
  authType: 'signup',
  disabled: false
};

export default FacebookLoginButton;
