import React from 'react';
import { Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import ReactHtmlParser from 'react-html-parser';

// utils
import i18n from 'utils/i18n';

// styles
import { mediaQuery } from 'styles/helpers/layout';
import { fonts } from '@lumoslabs/react_lux';

const styles = StyleSheet.create({
  // match size of input labels, which are capped at a certain size on large screens
  agreementCol: {
    marginBottom: 16
  },
  agreementFont: fonts.customFontScaling(fonts.h6, {
    [mediaQuery.minWidth.desktopSmall]: {
      fontSize: '1.0266em'
    }
  }),
  agreement: {
    textAlign: 'center'
  }
});

function TermsOfServiceAgreement() {
  const caPrivacyLink = '/privacy_policy/#what-information-we-collect';
  const termsOfServiceLink = '/terms_of_service';
  const privacyPolicyLink = '/privacy_policy';

  return (
    <Col xs="10" className={css(styles.agreementCol)}>
      <p className={css(styles.agreementFont, styles.agreement)}>
        {ReactHtmlParser(
          i18n.t('RegistrationForm.TermsOfServiceAgreement', { caPrivacyLink, termsOfServiceLink, privacyPolicyLink })
        )}
      </p>
    </Col>
  );
}

export default TermsOfServiceAgreement;
