import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { css, StyleSheet } from 'aphrodite';
import ReactHtmlParser from 'react-html-parser';

// utils
import i18n from 'utils/i18n';

// styles
import { mediaQuery } from 'styles/helpers/layout';

// child components
import InputFeedbackIcon from 'components/ui/forms/InputFeedbackIcon';
import InputFeedbackMessage from 'components/ui/forms/InputFeedbackMessage';
import { fonts } from '@lumoslabs/react_lux';
import BirthdayInputListItem from './BirthdayInputListItem';

const styles = StyleSheet.create({
  labelText: fonts.customFontScaling(fonts.h6, {
    [mediaQuery.minWidth.desktopSmall]: {
      fontSize: '1.0266em'
    }
  }),
  linkStyle: {
    textDecoration: 'underline'
  },
  list: {
    position: 'relative'
  },
  tooltip: {
    paddingBottom: 5,
    zIndex: 100000
  },
  noFeedbackMessageCol: {
    marginTop: 10
  },
  feedbackMessageCol: {
    marginTop: 2,
    marginBottom: 2,
    height: 32,
    paddingLeft: 30,
    paddingTop: 10,
    [mediaQuery.maxWidth.desktopSmall]: {
      height: 48
    }
  }
});

class BirthdayInputList extends React.Component {
  state = {
    receivedInput: false,
    listItemValid: true
  };

  provideFeedback = value => {
    const { formErrors } = this.props;
    this.setState({
      receivedInput: value !== '',
      listItemValid: formErrors.birthday === ''
    });
  };

  render() {
    const { dobDay, dobMonth, dobYear, handleInputChange, formErrors } = this.props;
    const { receivedInput, listItemValid } = this.state;

    return (
      <Row className={css(styles.list)}>
        <Col xs="12" className={css(styles.tooltip)}>
          <span className={css(styles.labelText)}>
            {ReactHtmlParser(i18n.t('RegistrationForm.BirthdayInputList.label.birthday'))}
            {'*'}
          </span>
        </Col>
        <Row>
          <BirthdayInputListItem
            handleInputChange={handleInputChange}
            value={dobMonth}
            type="dobMonth"
            provideFeedback={() => {}}
            valid={listItemValid}
            labelText={i18n.t('RegistrationForm.BirthdayInputList.label.dobMonth')}
          />
          <BirthdayInputListItem
            handleInputChange={handleInputChange}
            value={dobDay}
            type="dobDay"
            provideFeedback={() => {}}
            valid={listItemValid}
            labelText={i18n.t('RegistrationForm.BirthdayInputList.label.dobDay')}
          />
          <BirthdayInputListItem
            handleInputChange={handleInputChange}
            value={dobYear}
            type="dobYear"
            provideFeedback={() => this.provideFeedback(dobYear)}
            valid={listItemValid}
            labelText={i18n.t('RegistrationForm.BirthdayInputList.label.dobYear')}
          />
          <InputFeedbackIcon itemValid={listItemValid} receivedInput={receivedInput} />
          <Col
            xs="11"
            lg="12"
            className={
              receivedInput && !listItemValid ? css(styles.feedbackMessageCol) : css(styles.noFeedbackMessageCol)
            }
          >
            <InputFeedbackMessage feedback={formErrors.birthday} receivedInput={receivedInput} type="birthday" />
          </Col>
        </Row>
      </Row>
    );
  }
}

BirthdayInputList.propTypes = {
  dobDay: PropTypes.string,
  dobMonth: PropTypes.string,
  dobYear: PropTypes.string,
  handleInputChange: PropTypes.func,
  formErrors: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    birthday: PropTypes.string
  }).isRequired
};

BirthdayInputList.defaultProps = {
  dobDay: '',
  dobMonth: '',
  dobYear: '',
  handleInputChange: () => undefined
};

export default BirthdayInputList;
