import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import GoogleLogin from 'react-google-login';
import { RoundedButton, whiteAt98, fontStyles } from '@lumoslabs/react_lux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import i18n from 'utils/i18n';

const googleBlue = '#4285F4';
const styles = StyleSheet.create({
  button: {
    display: 'inline-block',
    position: 'relative',
    paddingLeft: 40,
    paddingRight: 20,
    marginRight: '1%',
    width: '49%',
    textAlign: 'center',
    color: whiteAt98,
    backgroundColor: googleBlue,
    ':hover': {
      backgroundColor: googleBlue
    }
  },
  icon: {
    position: 'absolute',
    left: 25,
    top: 17
  }
});

class GoogleLoginButton extends React.Component {
  static failureResponse(response) {
    console.log('Failure:');
    console.log(response);
  }

  static handleGoogleButtonClick(event, onClick) {
    event.preventDefault();
    onClick();
  }

  constructor(props) {
    super(props);
    this.successResponse = this.successResponse.bind(this);
  }

  successResponse({ profileObj, tokenObj }) {
    const { successCallBack } = this.props;
    const { email } = profileObj;
    const { access_token: accessToken, id_token: idToken } = tokenObj;
    successCallBack(email, accessToken, idToken);
  }

  render() {
    const { GATSBY_GOOGLE_SIGNIN_CLIENT_ID } = process.env;
    const { disabled } = this.props;
    return (
      <GoogleLogin
        clientId={GATSBY_GOOGLE_SIGNIN_CLIENT_ID}
        onSuccess={this.successResponse}
        onFailure={GoogleLoginButton.failureResponse}
        render={({ onClick }) => (
          <RoundedButton
            styles={[fontStyles.a, styles.button]}
            onClick={event => GoogleLoginButton.handleGoogleButtonClick(event, onClick)}
            disabled={disabled}
            // eslint-disable-next-line no-script-url
            href="javascript:void(0);"
            track="true"
            trackButtonPress="signup_google"
            trackLocation="body"
          >
            <FontAwesomeIcon icon={faGoogle} className={css(styles.icon)} size="s" />
            {i18n.t('registrationForm.googleLoginButton.signUp')}
          </RoundedButton>
        )}
      />
    );
  }
}

GoogleLoginButton.propTypes = {
  successCallBack: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

GoogleLoginButton.defaultProps = {
  disabled: false
};

export default GoogleLoginButton;
