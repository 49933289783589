import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import { Link } from 'react-scroll';

import * as layout from 'styles/helpers/layout';

import leftArrow from './images/leftarrow.png';
import CtaButton from './ui/CtaButton';

const styles = StyleSheet.create({
  richBackgroundStacker: {
    marginTop: 0, // refactor this out to the firstStack class
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: 0,
    maxHeight: 'calc(100% - 65px)',
    [layout.mediaQuery.maxWidth.tabletLarge]: {
      maxHeight: 'unset'
    }
  },
  videoContainer: {
    width: '100%',
    zIndex: -1
  },
  video: {
    visibility: 'visible',
    width: '100%',
    display: 'flex',
    [layout.mediaQuery.maxWidth.tabletLarge]: {
      visibility: 'hidden'
    }
  },
  downArrowWrapper: {
    zIndex: 1,
    position: 'absolute',
    top: '90%',
    left: '50%'
  },
  downArrow: {
    zIndex: 100,
    width: '15px',
    height: 'auto',
    transform: 'rotate(-90deg)',
    webkitTransform: 'rotate(-90deg)',
    margin: '0px auto',
    cursor: 'pointer'
  },
  foreground: {
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.6)',
    position: 'absolute',
    [layout.mediaQuery.maxWidth.tabletLarge]: {
      background: 'none'
    }
  },
  wrap: {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    webkitTransform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    maxWidth: '620px',
    width: '85%',
    textAlign: 'center'
  },
  headline: {
    margin: '0 auto',
    marginBottom: '25px',
    fontSize: '34px',
    lineHeight: '48px',
    color: '#fff',
    [layout.mediaQuery.maxWidth.tablet]: {
      fontSize: 25,
      lineHeight: '31px'
    }
  },
  subhead: {
    fontSize: '20px',
    lineHeight: '29px',
    color: '#fff',
    textRendering: 'optimizelegibility',
    fontWeight: '500'
  },
  button: {
    marginTop: '5px'
  }
});

function renderDownArrow(navArrowDown) {
  if (!navArrowDown) {
    return null;
  }
  return (
    <div className={css(styles.downArrowWrapper)}>
      <Link className="test6" to="anchor" spy smooth duration={500}>
        <img className={css(styles.downArrow)} src={leftArrow} alt="down arrow" />
      </Link>
    </div>
  );
}

function RichBackgroundStacker({ content }) {
  const {
    navArrowDown,
    backgroundColor,
    backgroundImage,
    mobileBackgroundImage,
    tabletLargeHeight,
    tabletHeight,
    mobileHeight,
    headline,
    subhead,
    ctaButton,
    backgroundWebm,
    backgroundMp4
  } = content;

  const styleOverrides = StyleSheet.create({
    container: {
      backgroundColor,
      backgroundImage: `url(${backgroundImage.file.url})`,
      [layout.mediaQuery.maxWidth.tabletLarge]: {
        backgroundImage: `url(${mobileBackgroundImage.file.url})`,
        height: tabletLargeHeight
      },
      [layout.mediaQuery.maxWidth.tablet]: {
        height: tabletHeight
      },
      [layout.mediaQuery.maxWidth.mobile]: {
        height: mobileHeight
      }
    }
  });

  return (
    <Container fluid className={css(styles.richBackgroundStacker, styleOverrides.container)}>
      <div className={css(styles.foreground)}>
        <div className={css(styles.wrap)}>
          <h2 className={css(styles.headline)}>{headline}</h2>
          <h5 className={css(styles.subhead)}>{subhead}</h5>
          <CtaButton data={ctaButton} />
        </div>
        {renderDownArrow(navArrowDown)}
      </div>
      <div className={css(styles.videoContainer)}>
        <video className={css(styles.video)} autoPlay id="floating" loop="loop" muted preload="">
          <source src={backgroundWebm.file.url} />
          <source src={backgroundMp4.file.url} />
        </video>
      </div>
      <div id="anchor" className="element" />
    </Container>
  );
}

RichBackgroundStacker.propTypes = {
  content: PropTypes.node.isRequired
};

export default RichBackgroundStacker;

export const richBackgroundStackerFragment = graphql`
  fragment richBackgroundStackerFragment on ContentfulRichBackgroundStacker {
    slug
    title
    headline
    subhead
    ctaButton {
      cta
      linkTo
      clickEventName
    }
    navArrowDown
    backgroundColor
    tabletLargeHeight
    tabletHeight
    mobileHeight
    backgroundWebm {
      file {
        url
      }
    }
    backgroundMp4 {
      file {
        url
      }
    }
    backgroundImage {
      file {
        url
      }
    }
    mobileBackgroundImage {
      file {
        url
      }
    }
  }
`;
