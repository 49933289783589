import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import Img from 'gatsby-image';
import { lumosWhite, fonts } from '@lumoslabs/react_lux';

import { mediaQuery } from 'styles/helpers/layout';

const styles = StyleSheet.create({
  card: {
    textAlign: 'left'
  },
  copy: {
    opacity: 0.9,
    fontSize: 16,
    lineHeight: '25.6px',
    marginTop: 0
  },
  headline: {
    fontSize: '1.45em',
    lineHeight: '1.47em',
    fontWeight: 'normal',
    marginBottom: 12,
    [mediaQuery.maxWidth.tablet]: {
      marginBottom: 5,
      fontSize: 20,
      lineHeight: '27px'
    }
  },
  graphic: {
    margin: '0px auto'
  }
});

function BasicCard({ card, additionalStyles }) {
  const { headline, copy, graphic, graphicMaxWidth, graphicAltText, copyAlignment } = card;
  const { copyColor } = additionalStyles;
  const dynamicStyles = {
    graphic: {
      maxWidth: graphicMaxWidth || '100%'
    },
    copy: {
      textAlign: copyAlignment || 'left',
      color: copyColor || lumosWhite
    },
    headline: {
      color: copyColor || lumosWhite
    }
  };
  return (
    <div className={css(styles.card)}>
      {graphic && (
        <div style={dynamicStyles.graphic} className={css(styles.graphic)}>
          <Img fluid={graphic.fluid} alt={graphicAltText || ''} />
        </div>
      )}
      {headline && (
        <h3 style={(fonts.h4, dynamicStyles.headline)} className={css(styles.headline)}>
          {headline}
        </h3>
      )}
      {copy && (
        <p style={(fonts.p, dynamicStyles.copy)} className={css(styles.copy)}>
          {copy}
        </p>
      )}
    </div>
  );
}

export const stackerBasicCardFragment = graphql`
  fragment stackerBasicCardFragment on ContentfulCard {
    id
    headline
    copy
    copyAlignment
    graphicMaxWidth
    graphicAltText
    graphic {
      fluid(maxWidth: 840, resizingBehavior: SCALE, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;

BasicCard.propTypes = {
  card: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    copy: PropTypes.string,
    graphic: PropTypes.shape({
      fluid: PropTypes.node
    }),
    graphicMaxWidth: PropTypes.number,
    graphicAltText: PropTypes.string,
    copyAlignment: PropTypes.string
  }).isRequired,
  additionalStyles: PropTypes.shape({
    copyColor: PropTypes.string
  })
};

BasicCard.defaultProps = {
  additionalStyles: {}
};

export default BasicCard;
