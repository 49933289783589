import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { StyleSheet, css } from 'aphrodite';

import * as layout from 'styles/helpers/layout';

const initialStyles = {
  image: {
    width: 840,
    margin: '0px auto',
    [layout.mediaQuery.maxWidth.tabletLarge]: {
      width: '100%'
    }
  }
};

function MainImage({ fluid, styleOverrides, mainImageAltText }) {
  const dynamicStyles = StyleSheet.create({
    image: {
      ...initialStyles.image,
      ...styleOverrides
    }
  });
  return <Img className={css(dynamicStyles.image)} fluid={fluid} alt={mainImageAltText || ''} />;
}

MainImage.propTypes = {
  fluid: PropTypes.node,
  mainImageAltText: PropTypes.string,
  styleOverrides: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string
  })
};

MainImage.defaultProps = {
  fluid: {},
  mainImageAltText: '',
  styleOverrides: {}
};

export default MainImage;
