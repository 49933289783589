import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import InputLabel from 'components/ui/forms/InputLabel';
import TextInput from 'components/ui/forms/TextInput';

// utils
import i18n from 'utils/i18n';

const styles = StyleSheet.create({
  inputCol: {
    paddingRight: 0,
    ':nth-of-type(4)': {
      paddingRight: 15
    }
  },
  labelCol: {
    paddingLeft: 15
  },
  birthdayCol: {
    padding: 0,
    ':nth-of-type(1)': {
      paddingLeft: 15
    },
    ':nth-of-type(3)': {
      paddingRight: 30
    }
  },
  input: {
    textAlign: 'center',
    padding: 0
  },
  inputYear: {
    textAlign: 'center',
    padding: 15
  }
});

const registrationPostParamsMap = {
  dobYear: 'date_of_birth(1i)',
  dobMonth: 'date_of_birth(2i)',
  dobDay: 'date_of_birth(3i)'
};

const profileModelFieldName = type => `user[profile][${registrationPostParamsMap[type]}]`;

function BirthdayInputListItem({ type, value, handleInputChange, valid, labelText, provideFeedback }) {
  return (
    <Col className={css(styles.birthdayCol)}>
      <Col className={css(styles.labelCol)}>
        <InputLabel type={type}>{labelText}</InputLabel>
      </Col>
      <Col className={css(styles.inputCol)}>
        <TextInput
          styles={[styles.input]}
          valid={valid}
          type="text"
          maxLength={type === 'dobYear' ? 4 : 2}
          name={profileModelFieldName(type)}
          placeholder={i18n.t(`RegistrationForm.BirthdayInputList.placeholderText.${type}`)}
          value={value}
          onBlur={provideFeedback}
          aria-describedby="birthday_error"
          aria-required="true"
          onChange={e => {
            handleInputChange(e, type);
          }}
          id={`unique_${type}`}
        />
      </Col>
    </Col>
  );
}

BirthdayInputListItem.propTypes = {
  value: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  provideFeedback: PropTypes.func.isRequired,
  labelText: PropTypes.string.isRequired,
  type: PropTypes.string,
  valid: PropTypes.bool
};

BirthdayInputListItem.defaultProps = {
  value: '',
  type: 'name',
  valid: true
};

export default BirthdayInputListItem;
