import i18n from 'utils/i18n';

const validateInputFor = {
  name(value) {
    const nameRegex = /^.{1,40}$/;
    return nameRegex.test(value);
  },
  email(value) {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(value);
  },
  login() {
    return true;
  },
  password(value) {
    const passwordRegex = /^.{5,40}$/;
    return passwordRegex.test(value);
  },
  birthday(value) {
    const birthdayRegex = /^([12]\d{3}-([1-9]|0[1-9]|1[0-2])-([1-9]|0[1-9]|[12]\d|3[01]))$/;
    if (!birthdayRegex.test(`${value.year}-${value.month}-${value.day}`)) {
      return false
    };
    // check that user is not immortal cyborg or Old Testament character
    const today = new Date();
    const aLongLongTimeAgo = new Date().setFullYear(today.getFullYear() - 130);

    // check that year is valid; necessary because date check below is too permissive
    // new Date('mumu-12-09') => Sun Dec 09 2001 00:00:00 GMT-0800 (Pacific Standard Time)
    const castIntToYear = parseInt(value.year, 10);
    if (isNaN(castIntToYear)) {
      return false;
    }

    const birthdayDatestring = `${value.year}-${value.month}-${value.day}`;
    const possibleBirthday = new Date(birthdayDatestring);

    // check that birthdayString generates a valid date object
    if (!(possibleBirthday instanceof Date) || isNaN(possibleBirthday)) {
      return false;
    }

    // check that user is neither immortal nor from the future
    // do not check if user is less than 13 just yet — we will check this on submit
    if (possibleBirthday < aLongLongTimeAgo || possibleBirthday > today) {
      return false;
    }

    return true;
  }
};

const InputFeedbackManager = {
  checkSuccess(type, value) {
    return validateInputFor[type](value);
  },
  respondToInput(receivedValidInput, type, value) {
    return receivedValidInput
      ? ''
      : i18n.t(`RegistrationForm.TextInputList.InputFeedbackManager.${type}.failure`, { [type]: value });
  }
};

export default InputFeedbackManager;
