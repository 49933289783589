import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { css, StyleSheet } from 'aphrodite';

// styles
import { mediaQuery } from 'styles/helpers/layout';

// child components
import TextInput from 'components/ui/forms/TextInput';
import InputLabel from 'components/ui/forms/InputLabel';
import InputFeedbackMessage from 'components/ui/forms/InputFeedbackMessage';
import InputFeedbackIcon from 'components/ui/forms/InputFeedbackIcon';

const styles = StyleSheet.create({
  listItem: {
    position: 'relative'
  },
  feedbackMessageCol: {
    marginTop: 2,
    marginBottom: 2,
    height: 16,
    [mediaQuery.maxWidth.tablet]: {
      height: 32
    }
  }
});

const userFieldsParamsMap = {
  name: 'first_name',
  email: 'email_address',
  login: 'login',
  password: 'password'
};

const inputTypesMap = type => {
  switch (type) {
    case 'name':
      return 'text';
    default:
      return type;
  }
};

const userModelFieldName = type => `user[${userFieldsParamsMap[type]}]`;

class TextInputListItem extends React.Component {
  state = {
    receivedInput: false,
    listItemValid: true
  };

  provideFeedback = value => {
    const { formErrors, type } = this.props;
    this.setState({
      receivedInput: value !== '',
      listItemValid: formErrors[type] === ''
    });
  };

  render() {
    const { type, value, handleInputChange, labelText, placeholderText, showFeedback, formErrors } = this.props;
    const { receivedInput, listItemValid } = this.state;
    return (
      <Row className={css(styles.listItem)}>
        <Col xs="12">
          <InputLabel type={type}>{labelText}</InputLabel>*
        </Col>
        <Col xs="11" lg="12">
          <TextInput
            valid={listItemValid}
            value={value}
            type={inputTypesMap(type)}
            size={40}
            maxLength={type === 'email' ? 60 : 40}
            name={userModelFieldName(type)}
            onBlur={() => {
              this.provideFeedback(value);
            }}
            placeholder={placeholderText}
            onChange={e => {
              handleInputChange(e, type);
            }}
            aria-invalid={!listItemValid}
            aria-describedby={`${type}_error`}
            aria-required="true"
            id={`unique_${type}`}
          />
        </Col>
        <Col xs="11" lg="12" className={css(styles.feedbackMessageCol)}>
          <InputFeedbackMessage
            feedback={showFeedback && formErrors[type]}
            receivedInput={receivedInput}
            type={type}
          />
        </Col>
        {showFeedback && <InputFeedbackIcon itemValid={listItemValid} receivedInput={receivedInput} />}
      </Row>
    );
  }
}

TextInputListItem.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  showFeedback: PropTypes.bool,
  formErrors: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    birthday: PropTypes.string
  }).isRequired
};

TextInputListItem.defaultProps = {
  type: '',
  value: '',
  placeholderText: '',
  showFeedback: false
};

export default TextInputListItem;
