import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { RoundedButton, whiteAt98, fontStyles } from '@lumoslabs/react_lux';
import { StyleSheet } from 'aphrodite';

import i18n from 'utils/i18n';

const styles = StyleSheet.create({
  button: {
    width: '100%',
    color: whiteAt98
  }
});

// TODO this was built as Birthday input but contains various non-birthday components
// Will need to be refactored in a separate branch
function SignUpButton({ buttonDisabled }) {
  return (
    <Col xs={11} xl={9}>
      <RoundedButton
        styles={[fontStyles.a, styles.button]}
        disabled={buttonDisabled}
        trackButtonPress="signup_email"
        trackLocation="body"
        html={{
          'data-cy': 'signup_email'
        }}
      >
        {i18n.t('RegistrationForm.SignUpButton')}
      </RoundedButton>
    </Col>
  );
}

SignUpButton.propTypes = {
  buttonDisabled: PropTypes.bool
};

SignUpButton.defaultProps = {
  buttonDisabled: false
};

export default SignUpButton;
