import React from 'react';
import PropTypes from 'prop-types';
import { lumosWhite, RoundedButton, fonts } from '@lumoslabs/react_lux';
import { Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import ReactHtmlParser from 'react-html-parser';

// utils
import i18n from 'utils/i18n';

const styles = StyleSheet.create({
  loggedInStyles: {
    backgroundColor: lumosWhite,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 10,
    paddingBottom: 30,
    ...fonts.h6,
    col: {
      marginTop: 30
    },
    textAlign: 'center'
  },
  button: {
    marginTop: 30,
    marginBottom: 30
  }
});

function LoggedInUser(props) {
  const {
    loggedInHeader,
    loggedInSubheader,
    loggedInCtaText,
    loggedInCtaUrl,
    loggedInLinkText,
    loggedInLinkUrl
  } = props;

  return (
    <Col xs="14" className={css(styles.loggedInStyles)}>
      <h2>{loggedInHeader || i18n.t('RegistrationForm.LoggedInUser.headline')}</h2>
      {loggedInSubheader && (
        <div
          /* eslint-disable-next-line react/no-danger  */
          dangerouslySetInnerHTML={{
            __html: loggedInSubheader.childMarkdownRemark.html
          }}
        />
      )}
      {!loggedInSubheader && <h4>{i18n.t('RegistrationForm.LoggedInUser.body')}</h4>}
      <div className={css(styles.button)}>
        <RoundedButton
          value={loggedInCtaText || i18n.t('RegistrationForm.LoggedInUser.cta')}
          href={loggedInCtaUrl || '/app/v4/dashboard'}
          trackLocation="logged_in_reg_form"
          trackButtonPress="goto_dashboard"
        />
      </div>
      <p>{ReactHtmlParser(i18n.t('RegistrationForm.LoggedInUser.or'))}</p>
      <a href={loggedInLinkUrl || '/purchase'} data-track-link-click="goto_purchase_landing">
        {loggedInLinkText || i18n.t('RegistrationForm.LoggedInUser.link')}
      </a>
    </Col>
  );
}

LoggedInUser.propTypes = {
  loggedInHeader: PropTypes.string,
  loggedInSubheader: PropTypes.string,
  loggedInCtaText: PropTypes.string,
  loggedInCtaUrl: PropTypes.string,
  loggedInLinkText: PropTypes.string,
  loggedInLinkUrl: PropTypes.string
}.isRequired;

export default LoggedInUser;
