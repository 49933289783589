import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { lumosWhite, gray1 } from '@lumoslabs/react_lux';
import { css, StyleSheet } from 'aphrodite';
import { Container, Row, Col } from 'reactstrap';

// utils
import FetchCsrfToken from 'utils/fetchCsrfToken';
import i18n from 'utils/i18n';

// styles
import { mediaQuery } from 'styles/helpers/layout';
import LoggedInUser from './LoggedInUser';

// child components
import RegistrationForm from '../../../RegistrationForm';
import Header from './Header';
import ValueProposition from './ValueProposition';
import AlreadyHaveAccount from './AlreadyHaveAccount';

const styles = StyleSheet.create({
  background: {
    backgroundColor: gray1
  },
  headerCenter: {
    textAlign: 'center'
  },
  headerLeft: {
    textAlign: 'left'
  },
  registrationContainer: {
    boxSizing: 'border-box',
    padding: '40px 102px',
    [mediaQuery.maxWidth.desktop]: {
      padding: '40px 40px'
    },
    [mediaQuery.maxWidth.desktopSmall]: {
      padding: '40px 0px 20px 0px'
    },
    [mediaQuery.maxWidth.tablet]: {
      padding: '20px 0px'
    },
    [mediaQuery.maxWidth.mobileLarge]: {
      padding: 0
    }
  }
});

class RegistrationFormStacker extends React.Component {
  constructor(props) {
    super(props);
    this.fetchCsrfToken = FetchCsrfToken.bind(this);
    this.state = {
      csrfToken: '',
      firstName: null,
      userLoggedIn: false
    };
  }

  componentDidMount() {
    typeof window !== 'undefined' && window.addEventListener('resize', this.resize);
    this.fetchCsrfToken((csrfToken) => this.setState({ csrfToken }));
    const { GATSBY_LUMOSITY_HOST } = process.env;
    // Check if user is logged in
    fetch(`${GATSBY_LUMOSITY_HOST}/api/v2/session/basic_user_info`, {
      credentials: 'include'
    }).then((response) => {
      if (response.ok) {
        response.json().then(({ first_name: firstName }) => {
          this.setState({ firstName, userLoggedIn: true });
        });
      }
    });
  }

  componentWillUnmount() {
    typeof window !== 'undefined' && window.removeEventListener('resize', this.resize);
  }

  resize = () => this.forceUpdate();

  render() {
    const { csrfToken, firstName, userLoggedIn } = this.state;
    const { content } = this.props;
    const mdOrder = userLoggedIn ? 1 : 2;
    const xsOrder = userLoggedIn ? 2 : 1;
    const inlineStyles = StyleSheet.create({
      leftColumn: {
        [mediaQuery.minWidth.tablet]: {
          backgroundColor: content.backgroundColor || lumosWhite
        }
      }
    });

    return (
      <div className={css(styles.background)}>
        <Container className={css(styles.registrationContainer)}>
          <Row>
            <Col
              xs={{ size: 12, order: xsOrder }}
              md={{ size: 5, order: mdOrder }}
              className={css(inlineStyles.leftColumn)}
            >
              {!userLoggedIn && <Header title={content.title} subtitle={content.subtitle} />}

              <ValueProposition
                customStyles={{
                  backgroundColor: content.backgroundColor || lumosWhite,
                  textColor: content.leftColTextColor
                }}
                copy={{
                  header: content.header,
                  subheader: content.subheader
                }}
                image={content.image}
                imageAltText={{ imageAltText: content.imageAltText }}
              />
            </Col>

            {!userLoggedIn && (
              <Col xs={{ size: 12, order: 2 }} md={{ size: 7, order: 2 }}>
                <RegistrationForm csrfToken={csrfToken} />
                <AlreadyHaveAccount
                  customLoginUrl={content.customLoginUrl || '/login'}
                  customLoginText={content.customLoginText || i18n.t('RegistrationForm.LoginText')}
                />
              </Col>
            )}
            {userLoggedIn && (
              <Col xs={{ size: 12, order: 1 }} md={{ size: 7, order: 2 }}>
                <LoggedInUser
                  loggedInFirstName={firstName}
                  loggedInHeader={content.loggedInHeader}
                  loggedInSubheader={content.loggedInSubheader}
                  loggedInCtaText={content.loggedInCtaText}
                  loggedInCtaUrl={content.loggedInCtaUrl}
                  loggedInLinkText={content.loggedInLinkText}
                  loggedInLinkUrl={content.loggedInLinkUrl}
                />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

export const registrationFormStackerFragment = graphql`
  fragment registrationFormStackerFragment on ContentfulRegistrationFormStacker {
    loggedInHeader
    loggedInSubheader {
      childMarkdownRemark {
        html
      }
    }
    loggedInCtaText
    loggedInCtaUrl
    loggedInLinkText
    loggedInLinkUrl
    customLoginUrl
    customLoginText
    title
    subtitle
    header
    subheader {
      childMarkdownRemark {
        html
      }
    }
    backgroundColor
    leftColTextColor
    imageAltText
    image {
      fluid(quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;

RegistrationFormStacker.propTypes = {
  content: PropTypes.shape({
    loggedInHeader: PropTypes.string,
    loggedInSubheader: PropTypes.string,
    loggedInCtaText: PropTypes.string,
    loggedInCtaUrl: PropTypes.string,
    loggedInLinkText: PropTypes.string,
    loggedInLinkUrl: PropTypes.string,
    backgroundColor: PropTypes.string,
    leftColTextColor: PropTypes.string,
    imageAltText: PropTypes.string,
    customLoginUrl: PropTypes.string,
    customLoginText: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.shape({
      subheader: PropTypes.string.isRequired
    }),
    image: PropTypes.shape({
      fluid: PropTypes.string
    }).isRequired
  }).isRequired
};
export default RegistrationFormStacker;
