import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { RoundedButton } from '@lumoslabs/react_lux';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

function CtaButton({ data }) {
  if (!data) {
    return null;
  }
  return (
    <RoundedButton disabled={false} href={data.linkTo} trackButtonPress={data.clickEventName}>
      {data.cta} <FontAwesomeIcon icon={faArrowRight} size="xs" />
    </RoundedButton>
  );
}

export const stackerCtaButtonFragment = graphql`
  fragment stackerCtaButtonFragment on ContentfulCtaButton {
    cta
    linkTo
    clickEventName
  }
`;

CtaButton.propTypes = {
  data: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    linkTo: PropTypes.string.isRequired,
    clickEventName: PropTypes.string
  }).isRequired
};

export default CtaButton;
