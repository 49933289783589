import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import { lumosWhite, grayNormal } from '@lumoslabs/react_lux';

import * as layout from 'styles/helpers/layout';
import Header from './Header';
import Cards from './Cards';
import CtaButton from '../ui/CtaButton';

function stylize(overrides) {
  return StyleSheet.create({
    main: {
      marginTop: '0px', // refactor this out to the firstStack class
      position: 'relative',
      padding: 0,
      paddingBottom: 15,
      ...overrides.main
    },
    centeredCol: {
      display: 'inline-block',
      float: 'none',
      /* inline-block space fix */
      marginRight: -4,
      textAlign: 'center'
    },
    cta: {
      marginTop: 50,
      marginBottom: 30,
      [layout.mediaQuery.maxWidth.desktop]: {
        marginBottom: 0
      }
    }
  });
}

const containerProperties = removeContainerTopPadding =>
  removeContainerTopPadding ? layout.containerWithoutTopPadding : layout.defaultContainerProperties;

function InfoStacker({ content, locale }) {
  const { lightText } = content;
  const copyColor = lightText ? lumosWhite : grayNormal;
  const styleOverrides = {
    main: {
      backgroundColor: content.backgroundColor || lumosWhite,
      ...containerProperties(content.removeContainerTopPadding),
      [layout.mediaQuery.minWidth.desktop]: {
        paddingBottom: 72
      },
      color: copyColor
    },
    mainImage: {
      maxWidth: content.mainImageMaxWidth || 'none'
    },
    header: {
      marginTop: content.headerVerticalOffset || 0
    },
    headline: {
      maxWidth: content.headlineMaxWidth || 'none',
      color: content.headlineColor || copyColor
    },
    subhead: {
      maxWidth: content.subheadMaxWidth || 'none',
      color: content.subheadColor || copyColor,
      marginTop: content.subheadTopMargin || 0
    },
    body: {
      maxWidth: content.bodyWidth || 'none'
    }
  };

  const styles = stylize(styleOverrides, locale);
  return (
    <Container fluid className={css(styles.main)}>
      <Container>
        <Header
          headline={content.headline}
          subhead={content.subhead}
          body={content.body}
          styleOverrides={styleOverrides}
          image={content.mainImage}
          imageLocation={content.mainImageLocation}
          mainImageAltText={content.mainImageAltText}
        />
        {content.cards && <Cards cards={content.cards} layout={content.cardLayout} additionalStyles={{ copyColor }} />}
        {content.ctaButton && (
          <Row className={css(styles.cta)}>
            <Col xs="12" className={css(styles.centeredCol)}>
              <CtaButton data={content.ctaButton} />
            </Col>
          </Row>
        )}
      </Container>
    </Container>
  );
}

export const infoStackerFragment = graphql`
  fragment infoStackerFragment on ContentfulInfoStacker {
    headline
    subhead
    body {
      body
      childMarkdownRemark {
        html
      }
    }
    removeContainerTopPadding: removeTopPadding
    headerVerticalOffset
    headlineMaxWidth
    headlineColor
    subheadColor
    subheadMaxWidth
    subheadTopMargin
    bodyWidth
    ctaButton {
      ...stackerCtaButtonFragment
    }
    cardLayout
    backgroundColor
    lightText
    mainImage {
      fluid(maxWidth: 840, resizingBehavior: SCALE, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    mainImageLocation
    mainImageMaxWidth
    mainImageAltText
    cards {
      ...stackerBasicCardFragment
    }
  }
`;

InfoStacker.propTypes = {
  locale: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default InfoStacker;
