function fetchCsrfToken(callback) {
  const { GATSBY_LUMOSITY_HOST } = process.env;
  const body = JSON.stringify({});
  fetch(`${GATSBY_LUMOSITY_HOST}/api/v2/csrf_token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body
  }).then(response =>
    response.json().then(({ token: csrfToken }) => {
      callback(csrfToken);
    })
  ).catch( err => {
    console.error("Invalid CSRF");
  });
}

export default fetchCsrfToken;
